<template>
  <v-container>
    <v-alert v-if="$store.state.auth.updateProfileApi=='success'"
  type="success"
>Profile Updated Successfully</v-alert>
    <v-alert v-if="$store.state.auth.updateProfileApi=='error'"
  type="error"
>Current Password does not match</v-alert>
    <v-card outlined elevation="0" class="p-4">
      <v-card-title>
        <h1 class="display-2 font-weight-medium">Profile</h1>

        <v-spacer></v-spacer>

        <!-- <v-btn icon fab class="info" dark>
          <v-icon>mdi-pencil</v-icon>
        </v-btn> -->
        
      </v-card-title>
        <div class="p-2 email-text">
          <label>Email : </label> {{userData.email}}
        </div>
      <v-card-text>
        <v-row dense>
          <v-col>
            <v-text-field v-model="userData.name" @input="userInput()" 
            :rules="[rules.required]"
             label="Name" outlined>
            </v-text-field>
          </v-col>
<!-- 
          <v-col>
            <v-text-field v-model="userData.email" label="Email" outlined>
            </v-text-field>
          </v-col> -->
        </v-row>

        <v-row dense>
          <v-col>
            <v-text-field label="Current Password" 
            hint="At least 8 characters"
                :rules="[rules.requiredPassword, rules.password]"
                v-model.trim = "current_password"
                @input="userInput()"
                @click:append="showPassword = !showPassword"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                outlined
            > </v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-text-field label="Password" 
            hint="At least 8 characters"
                :rules="[rules.requiredPassword, rules.password]"
                v-model.trim = "newpassword"
                @input="userInput()"
                @click:append="showPassword2 = !showPassword2"
                :type="showPassword2 ? 'text' : 'password'"
                :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                outlined
            > </v-text-field>
          </v-col>

          <v-col>
            <v-text-field label="Confirm Password"
             hint="At least 8 characters"
                :rules="[rules.requiredPassword, rules.passwordConfirm]"
                v-model.trim = "userData.password_confirmation"
                @input="userInput()"
                @click:append="showPassword3 = !showPassword3"
                :type="showPassword3 ? 'text' : 'password'"
                :append-icon="showPassword3 ? 'mdi-eye' : 'mdi-eye-off'"
             outlined> </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- <v-btn :disabled="button_validation==false"
         :loading="$store.state.auth.loading"
              @click="UpdateProfile()" depressed color="success"> Update </v-btn> -->
             <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="button_validation==false"
         :loading="$store.state.auth.loading"
          v-bind="attrs"
          v-on="on"
          depressed
          color="success"
        >
         Update
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 primary ">
          Confirm
        </v-card-title>

        <v-card-text class="mt-4 text-h6">
          Do you want to save changes ? 
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
                        depressed
            @click="UpdateProfile()"
          >
            Yes
          </v-btn>
          <v-btn
            color="primary"
            depressed
            @click="dialog = false"
          >
              No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      </v-card-actions>
    </v-card>


    <!-- <v-card outlined elevation="0" class="my-10 p-4">
      <v-card-title>
        <h1 class="display-2 font-weight-medium">Bank Account</h1>

        <v-spacer></v-spacer>

    
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col>
            <v-text-field label="Title" outlined> </v-text-field>
          </v-col>

          <v-col>
            <v-text-field label="Account No." outlined> </v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <v-text-field label="Bank Name" outlined> </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn disabled depressed color="success"> Update </v-btn>
      </v-card-actions>
    </v-card> -->
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  
  // data() {
  //   return {
  //     userData: {
  //       id: null,
  //       name: null,
  //       email: null,
  //       email_verified_at: null,
  //     },
  //   };
  // },
    data() {
    return {
      snackbar:false,
      multiLine:true,
      passwordErrorMessage:"A password must be eight characters including one uppercase letter,one lowercase letter, one special character and alphanumeric characters.",
      passwordErrorMessageConfimr:"Confirm Password should be same as password",
      // emailDomain: "@itcan.ae",
      passwordRules: [
				{ message:'One lowercase letter required.', regex:/[a-z]+/ },
				{ message:"One uppercase letter required.",  regex:/[A-Z]+/ },
				{ message:"8 characters minimum.", regex:/.{8,}/ },
				{ message:"One number required.", regex:/[0-9]+/ }
			],
      rules: {
        required: (value) => !!value || "Required.",
        requiredPassword: (value) => value!="" || "Required.",
        counter: (value) =>
          (value && value.length >= 8) || "Min 8 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        password: (value) => {
          if(value==null){

          }else{

            const pattern =
              /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/;
            return pattern.test(value) || this.passwordErrorMessage ;
          }
        },
        passwordConfirm: (value) => {
        
          return value==this.newpassword || this.passwordErrorMessageConfimr ;
        },
      },
      showPassword: false,
      showPassword2: false,
      showPassword3: false,
      newpassword: null,
      current_password: null,
      userData: {
        name: "",
        password: null,        
        password_confirmation: null,        
      },
      button_validation :false,
      isSuccess :false,
     dialog:false,
      errors:[],
    //    isDisabled: function(){
      
    // 	return !this.button_validation;
    // },
    };
  },
  methods: {
    userInput() {
  	
      // this.$store.state.auth.updateProfileApi =false;
      // console.log(this.userData.password_confirmation == this.userData.password && this.userData.name!="" && this.userData.password!="")
       if(this.userData.password_confirmation == this.newpassword && this.userData.name!="" && this.newpassword!="" && this.current_password!=""){
         this.button_validation= true
        }else{
         this.button_validation=false

       }
    },
     UpdateProfile() {
        this.dialog = false;
        // if(confirm("Do you want to save changes?")){
          const DATA = {
             'password': this.newpassword,  
             'name':this.userData.name,
             'current_password':this.current_password,         
             'password_confirmation': this.userData.password_confirmation,            
          };
          this.$store.dispatch("updateUser", DATA);
          this.isSuccess =true;
        // }
        
    },
   
     passwordValidation () {
			this.errors = []
			for (let condition of this.passwordRules) {
				if (!condition.regex.test(this.userData.password)) {
					this.errors.push(condition.message)
				}
			}
			if (this.errors.length === 0) {
				return { valid:true }
			} else {
				return { valid:false }
			}
		},
   
},
   
  watch: {
    user(val) {
      if (val) {
        this.userData = val;
      }
    },
  },
  computed: {
    ...mapGetters(["user"]),    
  
  },
  async mounted() {
    await this.$store.dispatch("getUser");
  },
};
</script>

<style>
</style>